import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { getAuth, signInWithEmailAndPassword, GoogleAuthProvider, OAuthProvider } from 'firebase/auth';
import { PopupService } from '../popup.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})

export class FirebaseService {
  constructor(
    private router: Router,  
    private ngFireAuth: AngularFireAuth,
    private popupService: PopupService,
    private translate: TranslateService
  ) {

  }

  signIn(email: string, password: string) {
    return signInWithEmailAndPassword(getAuth(), email, password);
  }

  async signInWithGoogle() {
    return await this.ngFireAuth.signInWithPopup(new GoogleAuthProvider());
  }

  async signInWithApple() {
    return await this.ngFireAuth.signInWithPopup(new OAuthProvider('apple.com'));
  }

  registerUser(email: string, password: string) {
    return this.ngFireAuth.createUserWithEmailAndPassword(email, password);
  }

  async sendVerificationMail() {
    this.ngFireAuth.useDeviceLanguage();
    return this.ngFireAuth.currentUser.then((u) => u.sendEmailVerification()).then(() => {
      this.router.navigate(["/login"]);
    });
  }

  async recoverAccount(passwordResetEmail: any) {
    return this.ngFireAuth.sendPasswordResetEmail(passwordResetEmail).then(() => {
      const popupData = {
        type: 1,
        message: this.translate.instant("AlertMsgCambioContrasenya")
      };
      this.popupService.openPopup(popupData);
    }).catch(() => {
      const popupData = {
        type: 1,
        message: this.translate.instant("AlertMsgEmailNoRegistrado")
      };
      this.popupService.openPopup(popupData);
    })
  }

  resetPassword(passwordResetEmail: any) {
    return new Promise(resolve => this.ngFireAuth.sendPasswordResetEmail(passwordResetEmail).then(() => {
      resolve(true);
    }).catch(() => {
      resolve(false);
    }));
  }

  async signOut() {
    localStorage.setItem("refreshUser", "true");
    return this.ngFireAuth.signOut().then(() => {
      localStorage.removeItem("user");
      sessionStorage.removeItem("pairings");
      sessionStorage.removeItem("selectedTags");
      this.router.navigate(["/login"]);
    });
  }
}